import {css, unsafeCSS} from 'lit';
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);


export const bottomNavigationStyle = css`
    :host {
        position: relative;
    }
    .bottom-navigation {
        display: flex;
        justify-content: center;
        background-color: var(--sbkBottomNavBarColorBgColor);
        padding: var(--sbkBottomNavBarSpacingSmallScreenInsetTop) var(--sbkCoreGridSmMargin);

        @media all and (min-width: ${breakpointMd}) {
            padding: var(--sbkBottomNavBarSpacingSmallScreenInsetTop) var(--sbkCoreGridMdMargin);
        }

        @media all and (min-width: ${breakpointLg}) {
            padding: var(--sbkBottomNavBarSpacingLargeScrInsetTop) var(--sbkCoreGridLgMargin);
        }

        @media all and (min-width: ${breakpointXl}) {
            padding:  var(--sbkBottomNavBarSpacingLargeScrInsetTop) var(--sbkCoreGridXlMargin);
        }
    }
`;
