import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import {bottomNavigationStyle} from "@/components/bottom-navigation/bottom-navigation.styles.ts";
import "./bottom-navigation.css"

/**
 * @slot default - Inhalt
 */
@customElement('sbk-bottom-navigation')
export class BottomNavigation extends LitElement {

    static get styles() {
        return bottomNavigationStyle;
    }

    render() {
        return html`
            <div class="bottom-navigation">
                <slot></slot>
            </div>
        `;

    }


}
